const DatabaseBlogData = [
  // 1-10: Connection & Loneliness
  {
    title: "Why We Struggle to Connect in a Hyperconnected World",
    URL: "why-we-struggle-to-connect",
    label: "Connection & Loneliness"
  },
  {
    title: "Loneliness in the Age of Social Media: Are We More Isolated Than Ever?",
    URL: "loneliness-in-age-of-social-media",
    label: "Connection & Loneliness"
  },
  {
    title: "The Art of Small Talk: How to Make It Less Painful and More Meaningful",
    URL: "art-of-small-talk",
    label: "Connection & Loneliness"
  },
  {
    title: "Can You Really ‘Click’ with Someone Instantly? The Science Behind Deep Connections",
    URL: "can-you-really-click-with-someone",
    label: "Connection & Loneliness"
  },
  {
    title: "Why Do Some People Feel Like Home?",
    URL: "why-some-people-feel-like-home",
    label: "Connection & Loneliness"
  },
  {
    title: "How to Find Meaningful Friendships as an Adult",
    URL: "find-meaningful-friendships-adult",
    label: "Connection & Loneliness"
  },
  {
    title: "What If Loneliness Was Just a Signal, Not a Sentence?",
    URL: "loneliness-as-signal-not-sentence",
    label: "Connection & Loneliness"
  },
  {
    title: "The Unspoken Rules of Social Connection: What We Get Wrong About Making Friends",
    URL: "unspoken-rules-of-social-connection",
    label: "Connection & Loneliness"
  },
  {
    title: "How to Make Someone Feel Truly Heard (And Why It Matters More Than Ever)",
    URL: "how-to-make-someone-feel-heard",
    label: "Connection & Loneliness"
  },
  {
    title: "The Real Reason You’re Struggling to Connect with People",
    URL: "real-reason-youre-struggling-to-connect",
    label: "Connection & Loneliness"
  },

  // 11-20: Love & Relationships
  {
    title: "Why Some People Are Impossible to Get Over",
    URL: "why-some-people-are-impossible-to-get-over",
    label: "Love & Relationships"
  },
  {
    title: "The Hidden Rules of Attraction You Don’t Even Realize You Follow",
    URL: "hidden-rules-of-attraction",
    label: "Love & Relationships"
  },
  {
    title: "What No One Tells You About Being in a Long-Term Relationship",
    URL: "what-no-one-tells-you-about-long-term-relationships",
    label: "Love & Relationships"
  },
  {
    title: "Do Opposites Really Attract? The Science Behind Compatibility",
    URL: "do-opposites-really-attract",
    label: "Love & Relationships"
  },
  {
    title: "What If Your Idea of Love Is Holding You Back?",
    URL: "is-your-idea-of-love-holding-you-back",
    label: "Love & Relationships"
  },
  {
    title: "The Conversations That Make or Break a Relationship",
    URL: "conversations-that-make-or-break-a-relationship",
    label: "Love & Relationships"
  },
  {
    title: "Why the Way You Argue Might Predict Whether Your Relationship Will Last",
    URL: "why-the-way-you-argue-matters",
    label: "Love & Relationships"
  },
  {
    title: "What If Your Partner Doesn’t Truly Know You?",
    URL: "what-if-your-partner-doesnt-know-you",
    label: "Love & Relationships"
  },
  {
    title: "How to Keep Curiosity Alive in Long-Term Love",
    URL: "keep-curiosity-alive-in-long-term-love",
    label: "Love & Relationships"
  },
  {
    title: "The Difference Between Being Loved and Being Understood",
    URL: "difference-between-loved-and-understood",
    label: "Love & Relationships"
  },

  // 21-30: Self-Discovery & Growth
  {
    title: "What If You’re Not Who You Think You Are?",
    URL: "what-if-youre-not-who-you-think",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How Your Past Shapes Your Conversations (And How to Break the Cycle)",
    URL: "how-your-past-shapes-conversations",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why the Best Conversations Make You a Little Uncomfortable",
    URL: "why-best-conversations-are-uncomfortable",
    label: "Self-Discovery & Growth"
  },
  {
    title: "What You Can Learn About Yourself from the Questions You Avoid",
    URL: "learn-from-questions-you-avoid",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Be More Interesting (Without Pretending to Be Someone You’re Not)",
    URL: "how-to-be-more-interesting",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why You Feel Drained After Talking to Certain People",
    URL: "why-you-feel-drained-after-talking",
    label: "Self-Discovery & Growth"
  },
  {
    title: "The Hidden Costs of Always Being ‘Nice’ in Conversations",
    URL: "hidden-costs-of-always-being-nice",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Ask Better Questions and Get to the Heart of a Conversation",
    URL: "how-to-ask-better-questions",
    label: "Self-Discovery & Growth"
  },
  {
    title: "What If You Could See Yourself Through Someone Else’s Eyes?",
    URL: "see-yourself-through-someone-elses-eyes",
    label: "Self-Discovery & Growth"
  },
  {
    title: "What’s the Deepest Impact You’ve Had on Someone Without Realizing It?",
    URL: "deepest-impact-youve-had",
    label: "Self-Discovery & Growth"
  },

  // 31-40: Unspoken Truths
  {
    title: "The Things We Think But Never Say: Why We Hold Back in Conversations",
    URL: "things-we-think-but-never-say",
    label: "Unspoken Truths"
  },
  {
    title: "Are You Actually a Good Listener? Here’s How to Tell",
    URL: "are-you-actually-a-good-listener",
    label: "Unspoken Truths"
  },
  {
    title: "Why We Keep Secrets (Even from Ourselves)",
    URL: "why-we-keep-secrets",
    label: "Unspoken Truths"
  },
  {
    title: "What If Someone You Love Never Really Liked You?",
    URL: "what-if-someone-you-love-never-liked-you",
    label: "Unspoken Truths"
  },
  {
    title: "How to Recognize When a Conversation Is About to Change Your Life",
    URL: "conversation-about-to-change-your-life",
    label: "Unspoken Truths"
  },
  {
    title: "Do People Need You More Than You Need Them?",
    URL: "do-people-need-you-more",
    label: "Unspoken Truths"
  },
  {
    title: "How to Find Out What Someone Is Really Thinking (Without Asking Directly)",
    URL: "how-to-find-what-someone-is-thinking",
    label: "Unspoken Truths"
  },
  {
    title: "The Subtle Ways People Show They’re Interested in You",
    URL: "subtle-ways-people-show-interest",
    label: "Unspoken Truths"
  },
  {
    title: "Why We Repeat the Same Conversations Over and Over Again",
    URL: "why-we-repeat-same-conversations",
    label: "Unspoken Truths"
  },
  {
    title: "What If You’re Misunderstanding Everyone Around You?",
    URL: "what-if-youre-misunderstanding-everyone",
    label: "Unspoken Truths"
  },

  // 41-50: The Science of Conversations
  {
    title: "Why Some Conversations Leave You Feeling Energized (And Others Drain You)",
    URL: "why-some-conversations-energize-or-drain",
    label: "The Science of Conversations"
  },
  {
    title: "The Hidden Psychology Behind Why People Overshare",
    URL: "hidden-psychology-of-oversharing",
    label: "The Science of Conversations"
  },
  {
    title: "What If Memory and Emotion Are More Connected Than You Think?",
    URL: "memory-and-emotion-more-connected",
    label: "The Science of Conversations"
  },
  {
    title: "Do We Really Have Free Will in the Conversations We Choose to Have?",
    URL: "do-we-really-have-free-will-in-conversations",
    label: "The Science of Conversations"
  },
  {
    title: "The Neuroscience of Deep Talk: How Meaningful Conversations Change Your Brain",
    URL: "neuroscience-of-deep-talk",
    label: "The Science of Conversations"
  },
  {
    title: "Why You Remember Some Conversations Forever and Forget Others Instantly",
    URL: "why-we-remember-some-conversations-forever",
    label: "The Science of Conversations"
  },
  {
    title: "How Your Subconscious Shapes the Way You Talk to Others",
    URL: "how-subconscious-shapes-conversations",
    label: "The Science of Conversations"
  },
  {
    title: "The Science of Eye Contact: Can It Really Create Instant Connection?",
    URL: "science-of-eye-contact",
    label: "The Science of Conversations"
  },
  {
    title: "What Your Tone of Voice Reveals About You Without You Knowing It",
    URL: "what-your-tone-of-voice-reveals",
    label: "The Science of Conversations"
  },
  {
    title: "Why Talking to Strangers Can Be Surprisingly Therapeutic",
    URL: "talking-to-strangers-can-be-therapeutic",
    label: "The Science of Conversations"
  },

  // 51-60: Conversations and Society
  {
    title: "Why We Need to Talk About Death More Often",
    URL: "why-we-need-to-talk-about-death",
    label: "Conversations and Society"
  },
  {
    title: "The Silent Epidemic of Shallow Conversations",
    URL: "silent-epidemic-of-shallow-conversations",
    label: "Conversations and Society"
  },
  {
    title: "Why Some Cultures Have Better Conversations Than Others",
    URL: "why-some-cultures-have-better-conversations",
    label: "Conversations and Society"
  },
  {
    title: "The Unwritten Social Rules of Deep Conversations",
    URL: "unwritten-social-rules-of-deep-talk",
    label: "Conversations and Society"
  },
  {
    title: "How Social Media Is Changing the Way We Speak (And Not for the Better)",
    URL: "how-social-media-changes-the-way-we-speak",
    label: "Conversations and Society"
  },
  {
    title: "What If Social Norms Are Preventing You from Having Meaningful Conversations?",
    URL: "social-norms-preventing-meaningful-conversations",
    label: "Conversations and Society"
  },
  {
    title: "The Topics We’re Afraid to Discuss (And Why We Should Anyway)",
    URL: "topics-were-afraid-to-discuss",
    label: "Conversations and Society"
  },
  {
    title: "How to Ask Someone a Hard Question Without Making It Awkward",
    URL: "ask-hard-question-without-awkwardness",
    label: "Conversations and Society"
  },
  {
    title: "Why Debating Ideas Is Dying (And How to Bring It Back)",
    URL: "why-debating-ideas-is-dying",
    label: "Conversations and Society"
  },
  {
    title: "The Future of Conversations: Will AI Ever Replace Deep Talk?",
    URL: "will-ai-replace-deep-talk",
    label: "Conversations and Society"
  },

  // 61-70: Personal Growth Through Conversations
  {
    title: "How the Right Conversation Can Change Your Life Instantly",
    URL: "how-right-conversation-can-change-your-life",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "Why You Should Talk to People Who Challenge Your Beliefs",
    URL: "talk-to-people-who-challenge-beliefs",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "How to Have More Conversations That Make You Rethink Everything",
    URL: "conversations-that-make-you-rethink",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "Why Most People Are Bad at Apologies (And How to Do It Right)",
    URL: "why-people-are-bad-at-apologies",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "How Your Most Honest Friend Might Be the Most Valuable Person in Your Life",
    URL: "your-most-honest-friend-most-valuable",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "The Conversations That Would Shock Your Younger Self",
    URL: "conversations-that-would-shock-your-younger-self",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "Why You Keep Talking to People Who Aren’t Good for You",
    URL: "why-you-keep-talking-to-toxic-people",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "What If You’ve Never Actually Had a Real Conversation with Your Parents?",
    URL: "never-had-real-conversation-with-parents",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "How to Keep Curiosity Alive as You Get Older",
    URL: "keep-curiosity-alive-as-you-age",
    label: "Personal Growth Through Conversations"
  },
  {
    title: "Why the Best Conversations Are Unplanned",
    URL: "why-best-conversations-are-unplanned",
    label: "Personal Growth Through Conversations"
  },

  // 71-80: The Unpredictability of Human Connection
  {
    title: "What If the Most Important Conversation of Your Life Hasn’t Happened Yet?",
    URL: "most-important-conversation-hasnt-happened-yet",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "The Unexpected People Who Might Change Your Life",
    URL: "unexpected-people-who-change-your-life",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "Why Some People Stay in Your Mind for Years After a Single Conversation",
    URL: "why-some-people-stay-in-mind-for-years",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "The One Question That Can Instantly Change Your Perspective",
    URL: "one-question-that-changes-perspective",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "What If the Way You See Yourself Is Completely Different from How Others See You?",
    URL: "what-if-way-you-see-yourself-is-different",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "Why Some of the Deepest Connections Happen in the Most Random Places",
    URL: "deepest-connections-in-random-places",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "How to Make an Ordinary Conversation Extraordinary",
    URL: "make-ordinary-conversation-extraordinary",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "The Surprising Power of Talking to People You Disagree With",
    URL: "power-of-talking-to-people-you-disagree-with",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "Why We Avoid the Conversations That Matter the Most",
    URL: "why-we-avoid-conversations-that-matter",
    label: "The Unpredictability of Human Connection"
  },
  {
    title: "What If a Missed Conversation Could Have Changed Your Life?",
    URL: "what-if-missed-conversation-could-change-life",
    label: "The Unpredictability of Human Connection"
  },

  // 81-90: Relationships, Love & Dating
  {
    title: "Why Some Couples Can Talk for Hours While Others Struggle for Words",
    URL: "why-some-couples-can-talk-for-hours",
    label: "Relationships, Love & Dating"
  },
  {
    title: "What Your Last Heartbreak Taught You About How You Communicate",
    URL: "what-last-heartbreak-taught-about-communication",
    label: "Relationships, Love & Dating"
  },
  {
    title: "How to Tell If Someone Is Emotionally Available Based on How They Talk",
    URL: "is-someone-emotionally-available",
    label: "Relationships, Love & Dating"
  },
  {
    title: "The Love Language You Speak Without Realizing It",
    URL: "the-love-language-you-speak",
    label: "Relationships, Love & Dating"
  },
  {
    title: "What If You’ve Never Had an Honest Conversation with Your Partner?",
    URL: "never-had-honest-conversation-with-partner",
    label: "Relationships, Love & Dating"
  },
  {
    title: "Why the Best Relationships Feel Like the Best Conversations",
    URL: "best-relationships-feel-like-best-conversations",
    label: "Relationships, Love & Dating"
  },
  {
    title: "What Your Arguments Say About the Future of Your Relationship",
    URL: "what-your-arguments-say-about-future",
    label: "Relationships, Love & Dating"
  },
  {
    title: "The Best Question to Ask on a First Date (That Most People Never Think of)",
    URL: "best-first-date-question",
    label: "Relationships, Love & Dating"
  },
  {
    title: "How to Know If You’re Actually Listening to Your Partner",
    URL: "how-to-know-if-youre-listening",
    label: "Relationships, Love & Dating"
  },
  {
    title: "What If Love Is Just a Series of Conversations That We Choose to Continue?",
    URL: "love-as-series-of-conversations",
    label: "Relationships, Love & Dating"
  },

  // 91-100: Thought-Provoking Questions & Reflections
  {
    title: "If You Had 10 Minutes Left to Live, What Would You Say and to Whom?",
    URL: "if-you-had-10-minutes-left",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "Which Conversations from Your Life Would You Replay If You Could?",
    URL: "which-conversations-would-you-replay",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "If You Could Ask Anyone in History One Question, Who and What Would It Be?",
    URL: "ask-anyone-in-history-one-question",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "What’s a Truth You’ve Avoided Saying Out Loud?",
    URL: "truth-youve-avoided-saying",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "What If Every Time You Spoke, It Was the Last Time That Person Would Hear You?",
    URL: "what-if-every-time-you-spoke-was-last-time",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "The One Question You Should Ask Yourself Every Day",
    URL: "one-question-to-ask-yourself-daily",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "If You Could Hear an Honest Answer to One Question, What Would You Ask?",
    URL: "if-you-could-hear-an-honest-answer",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "Why the Questions You Ask Matter More Than the Answers You Give",
    URL: "why-questions-matter-more-than-answers",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "How to Ask a Question That Stays with Someone Forever",
    URL: "ask-a-question-that-stays-forever",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "What’s the Most Honest Thing You’ve Ever Said?",
    URL: "most-honest-thing-youve-ever-said",
    label: "Thought-Provoking Questions & Reflections"
  },
  {
    title: "The Art of Asking: How to Spark Meaningful Conversations",
    URL: "the-art-of-asking-meaningful-conversations",
    label: "Deep Communication"
  },
  {
    title: "Beyond Small Talk: Unlocking the Power of Authentic Conversations",
    URL: "beyond-small-talk-authentic-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Loneliness Epidemic: How Deep Conversations Can Heal",
    URL: "loneliness-epidemic-deep-conversations-heal",
    label: "Deep Communication"
  },
  {
    title: "The Lost Art of Listening: Reviving Our Most Important Skill",
    URL: "lost-art-of-listening",
    label: "Deep Communication"
  },
  {
    title: "Conversations That Matter: Questions to Ask Your Loved Ones",
    URL: "conversations-that-matter-questions-for-loved-ones",
    label: "Deep Communication"
  },
  {
    title: "The Time We Waste: Reclaiming Meaningful Moments",
    URL: "time-we-waste-meaningful-moments",
    label: "Deep Communication"
  },
  {
    title: "The Power of Vulnerability: Embracing Authenticity in Conversations",
    URL: "power-of-vulnerability-in-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Technology on Our Conversations: A Double-Edged Sword",
    URL: "technology-impact-on-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Never Ask: Breaking the Silence",
    URL: "questions-we-never-ask",
    label: "Deep Communication"
  },
  {
    title: "The Role of Empathy in Deep Conversations",
    URL: "role-of-empathy-in-deep-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Beauty of Uncomfortable Conversations: Embracing Discomfort",
    URL: "beauty-of-uncomfortable-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Power of Storytelling: Connecting Through Shared Narratives",
    URL: "power-of-storytelling-shared-narratives",
    label: "Deep Communication"
  },
  {
    title: "The Importance of Active Listening in Building Strong Relationships",
    URL: "importance-of-active-listening-strong-relationships",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Define Us: Exploring Identity Through Conversation",
    URL: "questions-that-define-us-identity-and-conversation",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Mental Health",
    URL: "impact-of-deep-conversations-on-mental-health",
    label: "Deep Communication"
  },
  {
    title: "The Role of Curiosity in Fostering Meaningful Connections",
    URL: "role-of-curiosity-in-meaningful-connections",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Should Ask Ourselves: A Journey of Self-Discovery",
    URL: "questions-for-self-discovery",
    label: "Deep Communication"
  },
  {
    title: "The Power of Silence in Conversations: When Less Is More",
    URL: "power-of-silence-in-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Cultural Differences on Deep Conversations",
    URL: "impact-of-cultural-differences-on-deep-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Bring Us Closer: Building Intimacy Through Conversation",
    URL: "questions-that-build-intimacy",
    label: "Deep Communication"
  },
  {
    title: "The Role of Humor in Deep Conversations: Laughing Our Way to Connection",
    URL: "role-of-humor-in-deep-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Challenge Our Perspectives: Embracing Different Viewpoints",
    URL: "questions-that-challenge-perspectives",
    label: "Deep Communication"
  },
  {
    title: "The Power of Reflection in Deep Conversations: Looking Within",
    URL: "power-of-reflection-in-deep-conversations",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Personal Growth",
    URL: "deep-conversations-and-personal-growth",
    label: "Deep Communication"
  },
  {
    title: "The Role of Empathy in Bridging Divides: Connecting Through Understanding",
    URL: "empathy-in-bridging-divides",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Inspire Us: Finding Motivation Through Conversation",
    URL: "questions-that-inspire-motivation",
    label: "Deep Communication"
  },

  // 127-139 (first block of repeated titles)
  {
    title: "The Power of Active Listening in Building Strong Relationships",
    URL: "power-of-active-listening-strong-relationships-2",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Mental Health",
    URL: "deep-conversations-on-mental-health-2",
    label: "Deep Communication"
  },
  {
    title: "The Role of Curiosity in Fostering Meaningful Connections",
    URL: "role-of-curiosity-meaningful-connections-2",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Should Ask Ourselves: A Journey of Self-Discovery",
    URL: "questions-for-self-discovery-2",
    label: "Deep Communication"
  },
  {
    title: "The Power of Silence in Conversations: When Less Is More",
    URL: "power-of-silence-in-conversations-2",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Cultural Differences on Deep Conversations",
    URL: "cultural-differences-deep-conversations-2",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Bring Us Closer: Building Intimacy Through Conversation",
    URL: "questions-that-build-intimacy-2",
    label: "Deep Communication"
  },
  {
    title: "The Role of Humor in Deep Conversations: Laughing Our Way to Connection",
    URL: "humor-in-deep-conversations-2",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Challenge Our Perspectives: Embracing Different Viewpoints",
    URL: "questions-challenge-perspectives-2",
    label: "Deep Communication"
  },
  {
    title: "The Power of Reflection in Deep Conversations: Looking Within",
    URL: "reflection-in-deep-conversations-2",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Personal Growth",
    URL: "deep-conversations-personal-growth-2",
    label: "Deep Communication"
  },
  {
    title: "The Role of Empathy in Bridging Divides: Connecting Through Understanding",
    URL: "empathy-bridging-divides-2",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Inspire Us: Finding Motivation Through Conversation",
    URL: "questions-inspire-motivation-2",
    label: "Deep Communication"
  },

  // 140-152 (second block of repeated titles)
  {
    title: "The Power of Active Listening in Building Strong Relationships",
    URL: "power-of-active-listening-strong-relationships-3",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Mental Health",
    URL: "deep-conversations-on-mental-health-3",
    label: "Deep Communication"
  },
  {
    title: "The Role of Curiosity in Fostering Meaningful Connections",
    URL: "role-of-curiosity-meaningful-connections-3",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Should Ask Ourselves: A Journey of Self-Discovery",
    URL: "questions-for-self-discovery-3",
    label: "Deep Communication"
  },
  {
    title: "The Power of Silence in Conversations: When Less Is More",
    URL: "power-of-silence-in-conversations-3",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Cultural Differences on Deep Conversations",
    URL: "cultural-differences-deep-conversations-3",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Bring Us Closer: Building Intimacy Through Conversation",
    URL: "questions-that-build-intimacy-3",
    label: "Deep Communication"
  },
  {
    title: "The Role of Humor in Deep Conversations: Laughing Our Way to Connection",
    URL: "humor-in-deep-conversations-3",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Challenge Our Perspectives: Embracing Different Viewpoints",
    URL: "questions-challenge-perspectives-3",
    label: "Deep Communication"
  },
  {
    title: "The Power of Reflection in Deep Conversations: Looking Within",
    URL: "reflection-in-deep-conversations-3",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Personal Growth",
    URL: "deep-conversations-personal-growth-3",
    label: "Deep Communication"
  },
  {
    title: "The Role of Empathy in Bridging Divides: Connecting Through Understanding",
    URL: "empathy-bridging-divides-3",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Inspire Us: Finding Motivation Through Conversation",
    URL: "questions-inspire-motivation-3",
    label: "Deep Communication"
  },

  // 153-165 (third block of repeated titles)
  {
    title: "The Power of Active Listening in Building Strong Relationships",
    URL: "power-of-active-listening-strong-relationships-4",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Mental Health",
    URL: "deep-conversations-on-mental-health-4",
    label: "Deep Communication"
  },
  {
    title: "The Role of Curiosity in Fostering Meaningful Connections",
    URL: "role-of-curiosity-meaningful-connections-4",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Should Ask Ourselves: A Journey of Self-Discovery",
    URL: "questions-for-self-discovery-4",
    label: "Deep Communication"
  },
  {
    title: "The Power of Silence in Conversations: When Less Is More",
    URL: "power-of-silence-in-conversations-4",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Cultural Differences on Deep Conversations",
    URL: "cultural-differences-deep-conversations-4",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Bring Us Closer: Building Intimacy Through Conversation",
    URL: "questions-that-build-intimacy-4",
    label: "Deep Communication"
  },
  {
    title: "The Role of Humor in Deep Conversations: Laughing Our Way to Connection",
    URL: "humor-in-deep-conversations-4",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Challenge Our Perspectives: Embracing Different Viewpoints",
    URL: "questions-challenge-perspectives-4",
    label: "Deep Communication"
  },
  {
    title: "The Power of Reflection in Deep Conversations: Looking Within",
    URL: "reflection-in-deep-conversations-4",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Personal Growth",
    URL: "deep-conversations-personal-growth-4",
    label: "Deep Communication"
  },
  {
    title: "The Role of Empathy in Bridging Divides: Connecting Through Understanding",
    URL: "empathy-bridging-divides-4",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Inspire Us: Finding Motivation Through Conversation",
    URL: "questions-inspire-motivation-4",
    label: "Deep Communication"
  },

  // 166-178 (fourth block of repeated titles)
  {
    title: "The Power of Active Listening in Building Strong Relationships",
    URL: "power-of-active-listening-strong-relationships-5",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Mental Health",
    URL: "deep-conversations-on-mental-health-5",
    label: "Deep Communication"
  },
  {
    title: "The Role of Curiosity in Fostering Meaningful Connections",
    URL: "role-of-curiosity-meaningful-connections-5",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Should Ask Ourselves: A Journey of Self-Discovery",
    URL: "questions-for-self-discovery-5",
    label: "Deep Communication"
  },
  {
    title: "The Power of Silence in Conversations: When Less Is More",
    URL: "power-of-silence-in-conversations-5",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Cultural Differences on Deep Conversations",
    URL: "cultural-differences-deep-conversations-5",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Bring Us Closer: Building Intimacy Through Conversation",
    URL: "questions-that-build-intimacy-5",
    label: "Deep Communication"
  },
  {
    title: "The Role of Humor in Deep Conversations: Laughing Our Way to Connection",
    URL: "humor-in-deep-conversations-5",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Challenge Our Perspectives: Embracing Different Viewpoints",
    URL: "questions-challenge-perspectives-5",
    label: "Deep Communication"
  },
  {
    title: "The Power of Reflection in Deep Conversations: Looking Within",
    URL: "reflection-in-deep-conversations-5",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Personal Growth",
    URL: "deep-conversations-personal-growth-5",
    label: "Deep Communication"
  },
  {
    title: "The Role of Empathy in Bridging Divides: Connecting Through Understanding",
    URL: "empathy-bridging-divides-5",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Inspire Us: Finding Motivation Through Conversation",
    URL: "questions-inspire-motivation-5",
    label: "Deep Communication"
  },

  // 179-191 (fifth block of repeated titles)
  {
    title: "The Power of Active Listening in Building Strong Relationships",
    URL: "power-of-active-listening-strong-relationships-6",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Mental Health",
    URL: "deep-conversations-on-mental-health-6",
    label: "Deep Communication"
  },
  {
    title: "The Role of Curiosity in Fostering Meaningful Connections",
    URL: "role-of-curiosity-meaningful-connections-6",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Should Ask Ourselves: A Journey of Self-Discovery",
    URL: "questions-for-self-discovery-6",
    label: "Deep Communication"
  },
  {
    title: "The Power of Silence in Conversations: When Less Is More",
    URL: "power-of-silence-in-conversations-6",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Cultural Differences on Deep Conversations",
    URL: "cultural-differences-deep-conversations-6",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Bring Us Closer: Building Intimacy Through Conversation",
    URL: "questions-that-build-intimacy-6",
    label: "Deep Communication"
  },
  {
    title: "The Role of Humor in Deep Conversations: Laughing Our Way to Connection",
    URL: "humor-in-deep-conversations-6",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Challenge Our Perspectives: Embracing Different Viewpoints",
    URL: "questions-challenge-perspectives-6",
    label: "Deep Communication"
  },
  {
    title: "The Power of Reflection in Deep Conversations: Looking Within",
    URL: "reflection-in-deep-conversations-6",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Personal Growth",
    URL: "deep-conversations-personal-growth-6",
    label: "Deep Communication"
  },
  {
    title: "The Role of Empathy in Bridging Divides: Connecting Through Understanding",
    URL: "empathy-bridging-divides-6",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Inspire Us: Finding Motivation Through Conversation",
    URL: "questions-inspire-motivation-6",
    label: "Deep Communication"
  },

  // 192-200 (sixth block, partial — you can continue similarly if the text repeats more)
  {
    title: "The Power of Active Listening in Building Strong Relationships",
    URL: "power-of-active-listening-strong-relationships-7",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Deep Conversations on Mental Health",
    URL: "deep-conversations-on-mental-health-7",
    label: "Deep Communication"
  },
  {
    title: "The Role of Curiosity in Fostering Meaningful Connections",
    URL: "role-of-curiosity-meaningful-connections-7",
    label: "Deep Communication"
  },
  {
    title: "The Questions We Should Ask Ourselves: A Journey of Self-Discovery",
    URL: "questions-for-self-discovery-7",
    label: "Deep Communication"
  },
  {
    title: "The Power of Silence in Conversations: When Less Is More",
    URL: "power-of-silence-in-conversations-7",
    label: "Deep Communication"
  },
  {
    title: "The Impact of Cultural Differences on Deep Conversations",
    URL: "cultural-differences-deep-conversations-7",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Bring Us Closer: Building Intimacy Through Conversation",
    URL: "questions-that-build-intimacy-7",
    label: "Deep Communication"
  },
  {
    title: "The Role of Humor in Deep Conversations: Laughing Our Way to Connection",
    URL: "humor-in-deep-conversations-7",
    label: "Deep Communication"
  },
  {
    title: "The Questions That Challenge Our Perspectives: Embracing Different Viewpoints",
    URL: "questions-challenge-perspectives-7",
    label: "Deep Communication"
  },
  {
    number: 201,
    title: "The Silent Language of Connection: Beyond Words",
    URL: "silent-language-of-connection-beyond-words",
    label: "SEO & Engagement"
  },
  {
    number: 202,
    title: "Unlocking Vulnerability: The Key to Deeper Relationships",
    URL: "unlocking-vulnerability-deeper-relationships",
    label: "SEO & Engagement"
  },
  {
    number: 203,
    title: "Active Listening: The Lost Art of True Understanding",
    URL: "active-listening-lost-art-of-understanding",
    label: "SEO & Engagement"
  },
  {
    number: 204,
    title: "Navigating Conflict: Conversations That Heal, Not Harm",
    URL: "navigating-conflict-conversations-that-heal",
    label: "SEO & Engagement"
  },
  {
    number: 205,
    title: "The Power of Curiosity: Asking Questions That Matter",
    URL: "power-of-curiosity-asking-meaningful-questions",
    label: "SEO & Engagement"
  },
  {
    number: 206,
    title: "Combating Loneliness: Finding Connection in a Disconnected World",
    URL: "combating-loneliness-finding-connection",
    label: "SEO & Engagement"
  },
  {
    number: 207,
    title: "The Courage to Be Authentic: Embracing Your True Self",
    URL: "courage-to-be-authentic",
    label: "SEO & Engagement"
  },
  {
    number: 208,
    title: "The Philosophy of Conversation: Exploring the Essence of Human Exchange",
    URL: "philosophy-of-conversation-human-exchange",
    label: "SEO & Engagement"
  },
  {
    number: 209,
    title: "Beyond Small Talk: Building Meaningful Connections",
    URL: "beyond-small-talk-meaningful-connections",
    label: "SEO & Engagement"
  },
  {
    number: 210,
    title: "The Psychology of Connection: Understanding Our Need for Belonging",
    URL: "psychology-of-connection-need-for-belonging",
    label: "SEO & Engagement"
  },
  {
    number: 211,
    title: "The Digital Divide: Bridging the Gap in Online Communication",
    URL: "digital-divide-bridging-online-communication",
    label: "SEO & Engagement"
  },
  {
    number: 212,
    title: "The Art of Empathy: Walking in Another's Shoes",
    URL: "art-of-empathy-walking-in-anothers-shoes",
    label: "SEO & Engagement"
  },
  {
    number: 213,
    title: "The Power of Silence: Creating Space for Deeper Conversations",
    URL: "power-of-silence-deeper-conversations",
    label: "SEO & Engagement"
  },
  {
    number: 214,
    title: "The Role of Storytelling in Building Connection",
    URL: "role-of-storytelling-building-connection",
    label: "SEO & Engagement"
  },
  {
    number: 215,
    title: "The Impact of Shared Experiences: Creating Lasting Bonds",
    URL: "impact-of-shared-experiences-lasting-bonds",
    label: "SEO & Engagement"
  },
  {
    number: 216,
    title: "The Science of Connection: Hormones, Emotions, and Relationships",
    URL: "science-of-connection-hormones-and-emotions",
    label: "SEO & Engagement"
  },
  {
    number: 217,
    title: "The Importance of Face-to-Face Interaction in a Digital Age",
    URL: "importance-of-face-to-face-interaction",
    label: "SEO & Engagement"
  },
  {
    number: 218,
    title: "The Challenges of Intimacy: Overcoming Barriers to Connection",
    URL: "challenges-of-intimacy-overcoming-barriers",
    label: "SEO & Engagement"
  },
  {
    number: 219,
    title: "The Gift of Presence: Being Fully Present in Conversations",
    URL: "gift-of-presence-in-conversations",
    label: "SEO & Engagement"
  },
  {
    number: 220,
    title: "The Language of Love: Understanding Different Communication Styles",
    URL: "language-of-love-communication-styles",
    label: "SEO & Engagement"
  },
  {
    number: 221,
    title: "The Power of Shared Values: Building Connections on Common Ground",
    URL: "power-of-shared-values-common-ground",
    label: "SEO & Engagement"
  },
  {
    number: 222,
    title: "The Role of Trust in Building Strong Relationships",
    URL: "role-of-trust-in-strong-relationships",
    label: "SEO & Engagement"
  },
  {
    number: 223,
    title: "The Importance of Forgiveness in Maintaining Connections",
    URL: "importance-of-forgiveness-maintaining-connections",
    label: "SEO & Engagement"
  },
  {
    number: 224,
    title: "The Art of Saying No: Setting Boundaries in Relationships",
    URL: "art-of-saying-no-setting-boundaries",
    label: "SEO & Engagement"
  },
  {
    number: 225,
    title: "The Power of Gratitude: Expressing Appreciation and Strengthening Bonds",
    URL: "power-of-gratitude-expressing-appreciation",
    label: "SEO & Engagement"
  },
  {
    number: 226,
    title: "The Impact of Social Media on Our Ability to Connect",
    URL: "impact-of-social-media-on-connection",
    label: "SEO & Engagement"
  },
  {
    number: 227,
    title: "The Importance of Self-Awareness in Building Meaningful Connections",
    URL: "importance-of-self-awareness-in-connections",
    label: "SEO & Engagement"
  },
  {
    number: 228,
    title: "The Role of Emotional Intelligence in Effective Communication",
    URL: "role-of-emotional-intelligence-communication",
    label: "SEO & Engagement"
  },
  {
    number: 229,
    title: "The Power of Shared Laughter: Connecting Through Humor",
    URL: "power-of-shared-laughter",
    label: "SEO & Engagement"
  },
  {
    number: 230,
    title: "The Importance of Shared Experiences in Building Connection",
    URL: "importance-of-shared-experiences-connection",
    label: "SEO & Engagement"
  },
  {
    number: 231,
    title: "The Dangers of Assumptions: Avoiding Misunderstandings in Conversations",
    URL: "dangers-of-assumptions-avoid-misunderstandings",
    label: "SEO & Engagement"
  },
  {
    number: 232,
    title: "The Power of Shared Dreams: Connecting Through Aspirations",
    URL: "power-of-shared-dreams-aspirations",
    label: "SEO & Engagement"
  },
  {
    number: 233,
    title: "The Importance of Shared Memories: Building Connection Through Nostalgia",
    URL: "importance-of-shared-memories-nostalgia",
    label: "SEO & Engagement"
  },
  {
    number: 234,
    title: "The Role of Shared Interests in Building Connection",
    URL: "role-of-shared-interests-building-connection",
    label: "SEO & Engagement"
  },
  {
    number: 235,
    title: "The Power of Shared Values in Building Connection",
    URL: "power-of-shared-values-in-connection",
    label: "SEO & Engagement"
  },
  {
    number: 236,
    title: "The Importance of Shared Goals in Building Connection",
    URL: "importance-of-shared-goals-in-connection",
    label: "SEO & Engagement"
  },
  {
    number: 237,
    title: "The Role of Shared Experiences in Building Connection",
    URL: "role-of-shared-experiences-building-connection",
    label: "SEO & Engagement"
  },
  {
    number: 238,
    title: "The Power of Shared Struggles in Building Connection",
    URL: "power-of-shared-struggles-connection",
    label: "SEO & Engagement"
  },
  {
    number: 239,
    title: "The Importance of Shared Victories in Building Connection",
    URL: "importance-of-shared-victories-connection",
    label: "SEO & Engagement"
  },
  {
    number: 240,
    title: "The Role of Shared Losses in Building Connection",
    URL: "role-of-shared-losses-connection",
    label: "SEO & Engagement"
  },
  {
    number: 241,
    title: "The Power of Shared Fears in Building Connection",
    URL: "power-of-shared-fears-connection",
    label: "SEO & Engagement"
  },
  {
    number: 242,
    title: "The Importance of Shared Hopes in Building Connection",
    URL: "importance-of-shared-hopes-connection",
    label: "SEO & Engagement"
  },
  {
    number: 243,
    title: "The Role of Shared Beliefs in Building Connection",
    URL: "role-of-shared-beliefs-connection",
    label: "SEO & Engagement"
  },
  {
    number: 244,
    title: "The Power of Shared Doubts in Building Connection",
    URL: "power-of-shared-doubts-connection",
    label: "SEO & Engagement"
  },
  {
    number: 245,
    title: "The Importance of Shared Questions in Building Connection",
    URL: "importance-of-shared-questions-connection",
    label: "SEO & Engagement"
  },
  {
    number: 246,
    title: "The Role of Shared Discoveries in Building Connection",
    URL: "role-of-shared-discoveries-connection",
    label: "SEO & Engagement"
  },
  {
    number: 247,
    title: "The Power of Shared Insights in Building Connection",
    URL: "power-of-shared-insights-connection",
    label: "SEO & Engagement"
  },
  {
    number: 248,
    title: "The Importance of Shared Understandings in Building Connection",
    URL: "importance-of-shared-understandings-connection",
    label: "SEO & Engagement"
  },
  {
    number: 249,
    title: "The Role of Shared Perspectives in Building Connection",
    URL: "role-of-shared-perspectives-connection",
    label: "SEO & Engagement"
  },
  {
    number: 250,
    title: "The Power of Shared Journeys in Building Connection",
    URL: "power-of-shared-journeys-connection",
    label: "SEO & Engagement"
  },
  {
    number: 251,
    title: "The Importance of Shared Destinations in Building Connection",
    URL: "importance-of-shared-destinations-connection",
    label: "SEO & Engagement"
  },
  {
    number: 252,
    title: "The Role of Shared Paths in Building Connection",
    URL: "role-of-shared-paths-connection",
    label: "SEO & Engagement"
  },
  {
    number: 253,
    title: "The Power of Shared Roads in Building Connection",
    URL: "power-of-shared-roads-connection",
    label: "SEO & Engagement"
  },
  {
    number: 254,
    title: "The Importance of Shared Adventures in Building Connection",
    URL: "importance-of-shared-adventures-connection",
    label: "SEO & Engagement"
  },
  {
    number: 255,
    title: "The Role of Shared Experiences in Building Connection",
    URL: "role-of-shared-experiences-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 256,
    title: "The Power of Shared Moments in Building Connection",
    URL: "power-of-shared-moments-connection",
    label: "SEO & Engagement"
  },
  {
    number: 257,
    title: "The Importance of Shared Times in Building Connection",
    URL: "importance-of-shared-times-connection",
    label: "SEO & Engagement"
  },
  {
    number: 258,
    title: "The Role of Shared Spaces in Building Connection",
    URL: "role-of-shared-spaces-connection",
    label: "SEO & Engagement"
  },
  {
    number: 259,
    title: "The Power of Shared Places in Building Connection",
    URL: "power-of-shared-places-connection",
    label: "SEO & Engagement"
  },
  {
    number: 260,
    title: "The Importance of Shared Environments in Building Connection",
    URL: "importance-of-shared-environments-connection",
    label: "SEO & Engagement"
  },
  {
    number: 261,
    title: "The Role of Shared Worlds in Building Connection",
    URL: "role-of-shared-worlds-connection",
    label: "SEO & Engagement"
  },
  {
    number: 262,
    title: "The Power of Shared Universes in Building Connection",
    URL: "power-of-shared-universes-connection",
    label: "SEO & Engagement"
  },
  {
    number: 263,
    title: "The Importance of Shared Realities in Building Connection",
    URL: "importance-of-shared-realities-connection",
    label: "SEO & Engagement"
  },
  {
    number: 264,
    title: "The Role of Shared Truths in Building Connection",
    URL: "role-of-shared-truths-connection",
    label: "SEO & Engagement"
  },
  {
    number: 265,
    title: "The Power of Shared Lies in Building Connection",
    URL: "power-of-shared-lies-connection",
    label: "SEO & Engagement"
  },
  {
    number: 266,
    title: "The Importance of Shared Secrets in Building Connection",
    URL: "importance-of-shared-secrets-connection",
    label: "SEO & Engagement"
  },
  {
    number: 267,
    title: "The Role of Shared Confidences in Building Connection",
    URL: "role-of-shared-confidences-connection",
    label: "SEO & Engagement"
  },
  {
    number: 268,
    title: "The Power of Shared Vulnerabilities in Building Connection",
    URL: "power-of-shared-vulnerabilities-connection",
    label: "SEO & Engagement"
  },
  {
    number: 269,
    title: "The Importance of Shared Strengths in Building Connection",
    URL: "importance-of-shared-strengths-connection",
    label: "SEO & Engagement"
  },
  {
    number: 270,
    title: "The Role of Shared Weaknesses in Building Connection",
    URL: "role-of-shared-weaknesses-connection",
    label: "SEO & Engagement"
  },

  // Now the next 30 (271-300) repeat lines 241-270 (similar to the source text, which re-lists them),
  // but we'll keep them to match the original snippet's 100 total.

  {
    number: 271,
    title: "The Power of Shared Fears in Building Connection",
    URL: "power-of-shared-fears-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 272,
    title: "The Importance of Shared Hopes in Building Connection",
    URL: "importance-of-shared-hopes-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 273,
    title: "The Role of Shared Beliefs in Building Connection",
    URL: "role-of-shared-beliefs-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 274,
    title: "The Power of Shared Doubts in Building Connection",
    URL: "power-of-shared-doubts-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 275,
    title: "The Importance of Shared Questions in Building Connection",
    URL: "importance-of-shared-questions-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 276,
    title: "The Role of Shared Discoveries in Building Connection",
    URL: "role-of-shared-discoveries-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 277,
    title: "The Power of Shared Insights in Building Connection",
    URL: "power-of-shared-insights-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 278,
    title: "The Importance of Shared Understandings in Building Connection",
    URL: "importance-of-shared-understandings-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 279,
    title: "The Role of Shared Perspectives in Building Connection",
    URL: "role-of-shared-perspectives-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 280,
    title: "The Power of Shared Journeys in Building Connection",
    URL: "power-of-shared-journeys-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 281,
    title: "The Importance of Shared Destinations in Building Connection",
    URL: "importance-of-shared-destinations-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 282,
    title: "The Role of Shared Paths in Building Connection",
    URL: "role-of-shared-paths-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 283,
    title: "The Power of Shared Roads in Building Connection",
    URL: "power-of-shared-roads-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 284,
    title: "The Importance of Shared Adventures in Building Connection",
    URL: "importance-of-shared-adventures-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 285,
    title: "The Role of Shared Experiences in Building Connection",
    URL: "role-of-shared-experiences-connection-3",
    label: "SEO & Engagement"
  },
  {
    number: 286,
    title: "The Power of Shared Moments in Building Connection",
    URL: "power-of-shared-moments-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 287,
    title: "The Importance of Shared Times in Building Connection",
    URL: "importance-of-shared-times-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 288,
    title: "The Role of Shared Spaces in Building Connection",
    URL: "role-of-shared-spaces-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 289,
    title: "The Power of Shared Places in Building Connection",
    URL: "power-of-shared-places-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 290,
    title: "The Importance of Shared Environments in Building Connection",
    URL: "importance-of-shared-environments-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 291,
    title: "The Role of Shared Worlds in Building Connection",
    URL: "role-of-shared-worlds-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 292,
    title: "The Power of Shared Universes in Building Connection",
    URL: "power-of-shared-universes-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 293,
    title: "The Importance of Shared Realities in Building Connection",
    URL: "importance-of-shared-realities-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 294,
    title: "The Role of Shared Truths in Building Connection",
    URL: "role-of-shared-truths-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 295,
    title: "The Power of Shared Lies in Building Connection",
    URL: "power-of-shared-lies-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 296,
    title: "The Importance of Shared Secrets in Building Connection",
    URL: "importance-of-shared-secrets-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 297,
    title: "The Role of Shared Confidences in Building Connection",
    URL: "role-of-shared-confidences-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 298,
    title: "The Power of Shared Vulnerabilities in Building Connection",
    URL: "power-of-shared-vulnerabilities-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 299,
    title: "The Importance of Shared Strengths in Building Connection",
    URL: "importance-of-shared-strengths-connection-2",
    label: "SEO & Engagement"
  },
  {
    number: 300,
    title: "The Role of Shared Weaknesses in Building Connection",
    URL: "role-of-shared-weaknesses-connection-2",
    label: "SEO & Engagement"
  },
  {
    title: "The 5-Minute Connection: How One Question Changed Everything",
    URL: "the-5-minute-connection-how-one-question-changed-everything",
    label: "Communication & Empathy"
  },
  {
    title: "Why We Fear Silence: The Hidden Cost of Constant Small Talk",
    URL: "why-we-fear-silence-the-hidden-cost-of-constant-small-talk",
    label: "Communication & Empathy"
  },
  {
    title: "Digital Intimacy: Can Real Connection Happen Through Screens?",
    URL: "digital-intimacy-can-real-connection-happen-through-screens",
    label: "Digital Communication"
  },
  {
    title: "The Forgotten Art of Listening: Why Most People Only Pretend to Hear",
    URL: "the-forgotten-art-of-listening-why-most-people-only-pretend-to-hear",
    label: "Communication & Empathy"
  },
  {
    title: "Vulnerability Hangover: Why Opening Up Feels Terrifying (And Why It's Worth It)",
    URL: "vulnerability-hangover-why-opening-up-feels-terrifying-and-why-its-worth-it",
    label: "Personal Growth & Reflection"
  },
  {
    title: "Beyond \"How Are You?\": 20 Questions That Actually Start Real Conversations",
    URL: "beyond-how-are-you-20-questions-that-actually-start-real-conversations",
    label: "Communication & Empathy"
  },
  {
    title: "The Connection Paradox: Why We're More Isolated in the Age of \"Connection\"",
    URL: "the-connection-paradox-why-were-more-isolated-in-the-age-of-connection",
    label: "Connection & Loneliness"
  },
  {
    title: "What Your Conversation Style Reveals About Your Childhood",
    URL: "what-your-conversation-style-reveals-about-your-childhood",
    label: "Family & Parenting"
  },
  {
    title: "The Dinner Table Revolution: Reclaiming Family Connection One Meal at a Time",
    URL: "the-dinner-table-revolution-reclaiming-family-connection-one-meal-at-a-time",
    label: "Family & Parenting"
  },
  {
    title: "The Questions We're Afraid to Ask (And Why They Matter Most)",
    URL: "the-questions-were-afraid-to-ask-and-why-they-matter-most",
    label: "Communication & Empathy"
  },
  {
    title: "Why Strangers Sometimes Know Us Better Than Friends",
    URL: "why-strangers-sometimes-know-us-better-than-friends",
    label: "Friendship & Social Life"
  },
  {
    title: "The 30-Day Deep Talk Challenge That's Transforming Relationships",
    URL: "the-30-day-deep-talk-challenge-thats-transforming-relationships",
    label: "Love & Relationships"
  },
  {
    title: "Conversational Intelligence: Why Some People Connect Instantly (And How You Can Too)",
    URL: "conversational-intelligence-why-some-people-connect-instantly-and-how-you-can-too",
    label: "Communication & Empathy"
  },
  {
    title: "The Lost Connection: Rediscovering Intimacy in a Distracted World",
    URL: "the-lost-connection-rediscovering-intimacy-in-a-distracted-world",
    label: "Communication & Empathy"
  },
  {
    title: "Why Your Most Important Relationships Need Difficult Conversations",
    URL: "why-your-most-important-relationships-need-difficult-conversations",
    label: "Love & Relationships"
  },
  {
    title: "The Questions That Saved My Marriage: A Story of Rediscovery",
    URL: "the-questions-that-saved-my-marriage-a-story-of-rediscovery",
    label: "Love & Relationships"
  },
  {
    title: "Beyond Weather Talk: Converting Acquaintances to Friends Through Better Questions",
    URL: "beyond-weather-talk-converting-acquaintances-to-friends-through-better-questions",
    label: "Friendship & Social Life"
  },
  {
    title: "The Conversation Deficit: How Modern Life Steals Our Deepest Connections",
    URL: "the-conversation-deficit-how-modern-life-steals-our-deepest-connections",
    label: "Communication & Empathy"
  },
  {
    title: "What 1,000 Deep Conversations Taught Me About Human Nature",
    URL: "what-1000-deep-conversations-taught-me-about-human-nature",
    label: "Communication & Empathy"
  },
  {
    title: "The Science of Meaningful Connection: What Neuroscience Reveals About Deep Talk",
    URL: "the-science-of-meaningful-connection-what-neuroscience-reveals-about-deep-talk",
    label: "Science & Research"
  },
  {
    title: "First Date Revolution: Beyond the Resume Exchange",
    URL: "first-date-revolution-beyond-the-resume-exchange",
    label: "Love & Relationships"
  },
  {
    title: "The Morning Question Ritual That's Transforming Relationships",
    URL: "the-morning-question-ritual-thats-transforming-relationships",
    label: "Love & Relationships"
  },
  {
    title: "Why Children Ask Better Questions Than Adults (And What We Can Learn)",
    URL: "why-children-ask-better-questions-than-adults-and-what-we-can-learn",
    label: "Family & Parenting"
  },
  {
    title: "The Hidden Language of What's Not Said: Reading Between the Lines",
    URL: "the-hidden-language-of-whats-not-said-reading-between-the-lines",
    label: "Communication & Empathy"
  },
  {
    title: "Conversation as Meditation: The Mindfulness of True Dialogue",
    URL: "conversation-as-meditation-the-mindfulness-of-true-dialogue",
    label: "Communication & Empathy"
  },
  {
    title: "The End of Small Talk: Why Surface Conversations Are Becoming Obsolete",
    URL: "the-end-of-small-talk-why-surface-conversations-are-becoming-obsolete",
    label: "Communication & Empathy"
  },
  {
    title: "Conversational Time Travel: How the Right Questions Unlock Hidden Memories",
    URL: "conversational-time-travel-how-the-right-questions-unlock-hidden-memories",
    label: "Communication & Empathy"
  },
  {
    title: "The Friendship Depth Test: Are Your Connections as Strong as You Think?",
    URL: "the-friendship-depth-test-are-your-connections-as-strong-as-you-think",
    label: "Friendship & Social Life"
  },
  {
    title: "Why We Remember Conversations More Than Events: The Neuroscience of Connection",
    URL: "why-we-remember-conversations-more-than-events-the-neuroscience-of-connection",
    label: "Science & Research"
  },
  {
    title: "The Five Questions That Reveal Everything: What Therapists Ask Their Clients",
    URL: "the-five-questions-that-reveal-everything-what-therapists-ask-their-clients",
    label: "Communication & Empathy"
  },
  {
    title: "Digital Detox for Two: Reclaiming Conversation in a Tech-Saturated World",
    URL: "digital-detox-for-two-reclaiming-conversation-in-a-tech-saturated-world",
    label: "Digital Communication"
  },
  {
    title: "The Vulnerability Equation: How Sharing Fears Creates Unbreakable Bonds",
    URL: "the-vulnerability-equation-how-sharing-fears-creates-unbreakable-bonds",
    label: "Personal Growth & Reflection"
  },
  {
    title: "Generational Bridges: Questions That Connect Across Age Divides",
    URL: "generational-bridges-questions-that-connect-across-age-divides",
    label: "Communication & Empathy"
  },
  {
    title: "The Relationship Audit: Essential Questions for Couples at Every Stage",
    URL: "the-relationship-audit-essential-questions-for-couples-at-every-stage",
    label: "Love & Relationships"
  },
  {
    title: "Why We're Afraid of Deep Conversations (And How to Overcome It)",
    URL: "why-were-afraid-of-deep-conversations-and-how-to-overcome-it",
    label: "Communication & Empathy"
  },
  {
    title: "The Truth About Icebreakers: Moving Beyond Awkward to Authentic",
    URL: "the-truth-about-icebreakers-moving-beyond-awkward-to-authentic",
    label: "Personal Growth & Reflection"
  },
  {
    title: "Conversation Alchemy: Turning Small Talk into Gold",
    URL: "conversation-alchemy-turning-small-talk-into-gold",
    label: "Communication & Empathy"
  },
  {
    title: "The Geography of Conversation: How Physical Space Shapes Our Ability to Connect",
    URL: "the-geography-of-conversation-how-physical-space-shapes-our-ability-to-connect",
    label: "Communication & Empathy"
  },
  {
    title: "The Lost Art of the Long Walk: Why Moving Conversations Go Deeper",
    URL: "the-lost-art-of-the-long-walk-why-moving-conversations-go-deeper",
    label: "Communication & Empathy"
  },
  {
    title: "Beyond Politics: Finding Human Connection in Divided Times",
    URL: "beyond-politics-finding-human-connection-in-divided-times",
    label: "Communication & Empathy"
  },
  {
    title: "The Conversation Cure: How Deep Talk Improves Mental and Physical Health",
    URL: "the-conversation-cure-how-deep-talk-improves-mental-and-physical-health",
    label: "Communication & Empathy"
  },
  {
    title: "The Family History Project: Questions That Uncover Your Roots",
    URL: "the-family-history-project-questions-that-uncover-your-roots",
    label: "Family & Parenting"
  },
  {
    title: "Why Texting Kills Intimacy (And How to Bring Conversations Back to Life)",
    URL: "why-texting-kills-intimacy-and-how-to-bring-conversations-back-to-life",
    label: "Digital Communication"
  },
  {
    title: "The Dinner Party Renaissance: Hosting Gatherings That Foster Real Connection",
    URL: "the-dinner-party-renaissance-hosting-gatherings-that-foster-real-connection",
    label: "Communication & Empathy"
  },
  {
    title: "What Your Partner Isn't Telling You: Breaking the Silence in Relationships",
    URL: "what-your-partner-isnt-telling-you-breaking-the-silence-in-relationships",
    label: "Love & Relationships"
  },
  {
    title: "Cultural Conversation Differences: Understanding Global Communication Styles",
    URL: "cultural-conversation-differences-understanding-global-communication-styles",
    label: "Communication & Empathy"
  },
  {
    title: "The Question Journal: How Daily Reflection Transforms External Conversations",
    URL: "the-question-journal-how-daily-reflection-transforms-external-conversations",
    label: "Communication & Empathy"
  },
  {
    title: "The 7-Day Connection Challenge: One Week to Transformed Relationships",
    URL: "the-7-day-connection-challenge-one-week-to-transformed-relationships",
    label: "Love & Relationships"
  },
  {
    title: "Why Asking \"How Are You?\" Fails (And What to Ask Instead)",
    URL: "why-asking-how-are-you-fails-and-what-to-ask-instead",
    label: "Communication & Empathy"
  },
  {
    title: "The Work Conversation Revolution: Beyond Water Cooler Talk",
    URL: "the-work-conversation-revolution-beyond-water-cooler-talk",
    label: "Professional & Workplace"
  },
  {
    title: "Digital Natives vs. Digital Immigrants: Bridging the Conversation Gap",
    URL: "digital-natives-vs-digital-immigrants-bridging-the-conversation-gap",
    label: "Digital Communication"
  },
  {
    title: "The Empty Nest Dialogue: Rediscovering Partnership After Children Leave",
    URL: "the-empty-nest-dialogue-rediscovering-partnership-after-children-leave",
    label: "Love & Relationships"
  },
  {
    title: "First Impressions Reimagined: The Science of Memorable Introductions",
    URL: "first-impressions-reimagined-the-science-of-memorable-introductions",
    label: "Science & Research"
  },
  {
    title: "The Loneliness Epidemic: Connection as the Antidote to Modern Isolation",
    URL: "the-loneliness-epidemic-connection-as-the-antidote-to-modern-isolation",
    label: "Connection & Loneliness"
  },
  {
    title: "Beyond \"Fine\": Breaking the Script of Automatic Responses",
    URL: "beyond-fine-breaking-the-script-of-automatic-responses",
    label: "Communication & Empathy"
  },
  {
    title: "The Friendship Maintenance Myth: Why Deep Connections Don't Require Constant Contact",
    URL: "the-friendship-maintenance-myth-why-deep-connections-dont-require-constant-contact",
    label: "Friendship & Social Life"
  },
  {
    title: "The 3-Question Framework: How to Transform Any Conversation From Shallow to Significant",
    URL: "the-3-question-framework-how-to-transform-any-conversation-from-shallow-to-significant",
    label: "Professional & Workplace"
  },
  {
    title: "Why Travel Creates Deeper Conversations: The Psychology of Connection in New Places",
    URL: "why-travel-creates-deeper-conversations-the-psychology-of-connection-in-new-places",
    label: "Science & Research"
  },
  {
    title: "The Long-Distance Relationship Survival Guide: Creating Intimacy Across Miles",
    URL: "the-long-distance-relationship-survival-guide-creating-intimacy-across-miles",
    label: "Love & Relationships"
  },
  {
    title: "The Workplace Connection Deficit: Why Teams That Talk Perform Better",
    URL: "the-workplace-connection-deficit-why-teams-that-talk-perform-better",
    label: "Professional & Workplace"
  },
  {
    title: "The Teen Translation Guide: Questions That Open Adolescent Communication",
    URL: "the-teen-translation-guide-questions-that-open-adolescent-communication",
    label: "Family & Parenting"
  },
  {
    title: "Conversational Dark Matter: The Unsaid Words That Shape Our Relationships",
    URL: "conversational-dark-matter-the-unsaid-words-that-shape-our-relationships",
    label: "Love & Relationships"
  },
  {
    title: "The Conversation Compass: Finding Direction in Relationship Through Strategic Questions",
    URL: "the-conversation-compass-finding-direction-in-relationship-through-strategic-questions",
    label: "Love & Relationships"
  },
  {
    title: "Beyond \"Good Job\": Conversations That Actually Connect With Your Children",
    URL: "beyond-good-job-conversations-that-actually-connect-with-your-children",
    label: "Family & Parenting"
  },
  {
    title: "The Authenticity Paradox: Why Being Your \"Real Self\" Requires Skill and Practice",
    URL: "the-authenticity-paradox-why-being-your-real-self-requires-skill-and-practice",
    label: "Personal Growth & Reflection"
  },
  {
    title: "Conversational Time Zones: How Different Personalities Approach Disclosure",
    URL: "conversational-time-zones-how-different-personalities-approach-disclosure",
    label: "Communication & Empathy"
  },
  {
    title: "The Shared Memory Effect: How Conversations Create Collective Identity",
    URL: "the-shared-memory-effect-how-conversations-create-collective-identity",
    label: "Communication & Empathy"
  },
  {
    title: "Why Men and Women Often Miss Each Other in Conversation (And How to Connect)",
    URL: "why-men-and-women-often-miss-each-other-in-conversation-and-how-to-connect",
    label: "Communication & Empathy"
  },
  {
    title: "The 5 Languages of Deep Conversation: Discovering Your Dialogue Style",
    URL: "the-5-languages-of-deep-conversation-discovering-your-dialogue-style",
    label: "Communication & Empathy"
  },
  {
    title: "Digital Conversation Design: Creating Virtual Spaces That Foster Real Connection",
    URL: "digital-conversation-design-creating-virtual-spaces-that-foster-real-connection",
    label: "Digital Communication"
  },
  {
    title: "The Evolutionary Case for Deep Talk: Why Meaningful Conversation Helped Humans Survive",
    URL: "the-evolutionary-case-for-deep-talk-why-meaningful-conversation-helped-humans-survive",
    label: "Science & Research"
  },
  {
    title: "Beyond the Resume: Job Interviews That Reveal the Whole Person",
    URL: "beyond-the-resume-job-interviews-that-reveal-the-whole-person",
    label: "Professional & Workplace"
  },
  {
    title: "The 100-Year Conversation: Questions Worth Asking Across a Lifetime",
    URL: "the-100-year-conversation-questions-worth-asking-across-a-lifetime",
    label: "Communication & Empathy"
  },
  {
    title: "Conversation as Play: The Lost Art of Verbal Exchange as Recreation",
    URL: "conversation-as-play-the-lost-art-of-verbal-exchange-as-recreation",
    label: "Communication & Empathy"
  },
  {
    title: "The Dating App Escape: Moving From Profiles to Real Connection",
    URL: "the-dating-app-escape-moving-from-profiles-to-real-connection",
    label: "Communication & Empathy"
  },
  {
    title: "Why We Don't Ask the Questions We Really Want Answered",
    URL: "why-we-dont-ask-the-questions-we-really-want-answered",
    label: "Communication & Empathy"
  },
  {
    title: "The Leadership Listening Revolution: How Great Bosses Create Psychological Safety",
    URL: "the-leadership-listening-revolution-how-great-bosses-create-psychological-safety",
    label: "Professional & Workplace"
  },
  {
    title: "Conversation After Crisis: Rebuilding Connection When Trust Is Broken",
    URL: "conversation-after-crisis-rebuilding-connection-when-trust-is-broken",
    label: "Communication & Empathy"
  },
  {
    title: "The Friendship Formula: Conversations That Turn Acquaintances Into Confidants",
    URL: "the-friendship-formula-conversations-that-turn-acquaintances-into-confidants",
    label: "Friendship & Social Life"
  },
  {
    title: "Why Your Friend Circle Is Shrinking (And What Deep Conversations Can Do About It)",
    URL: "why-your-friend-circle-is-shrinking-and-what-deep-conversations-can-do-about-it",
    label: "Friendship & Social Life"
  },
  {
    title: "The Commuter Connection: Transforming Transit Time Into Relationship Building",
    URL: "the-commuter-connection-transforming-transit-time-into-relationship-building",
    label: "Love & Relationships"
  },
  {
    title: "Conversation as Creativity: How Dialogue Sparks Innovation and New Ideas",
    URL: "conversation-as-creativity-how-dialogue-sparks-innovation-and-new-ideas",
    label: "Communication & Empathy"
  },
  {
    title: "The Bedtime Question: A Simple Ritual That Transforms Relationships",
    URL: "the-bedtime-question-a-simple-ritual-that-transforms-relationships",
    label: "Love & Relationships"
  },
  {
    title: "Why Difficult People Are Worth Talking To: Finding Connection Across Differences",
    URL: "why-difficult-people-are-worth-talking-to-finding-connection-across-differences",
    label: "Communication & Empathy"
  },
  {
    title: "The Social Media Detox Effect: What Happens When We Talk Instead of Post",
    URL: "the-social-media-detox-effect-what-happens-when-we-talk-instead-of-post",
    label: "Digital Communication"
  },
  {
    title: "Conversational Consent: Creating Safety for Deep and Vulnerable Exchange",
    URL: "conversational-consent-creating-safety-for-deep-and-vulnerable-exchange",
    label: "Personal Growth & Reflection"
  },
  {
    title: "The 10 Questions That Predict Divorce (And How to Ask Them Constructively)",
    URL: "the-10-questions-that-predict-divorce-and-how-to-ask-them-constructively",
    label: "Love & Relationships"
  },
  {
    title: "Why We're Bad at Asking Good Questions (And How to Get Better)",
    URL: "why-were-bad-at-asking-good-questions-and-how-to-get-better",
    label: "Communication & Empathy"
  },
  {
    title: "The Art of the Follow-Up: How Second Questions Create Breakthrough Moments",
    URL: "the-art-of-the-follow-up-how-second-questions-create-breakthrough-moments",
    label: "Communication & Empathy"
  },
  {
    title: "Conversation as Community: How Shared Dialogue Creates Belonging",
    URL: "conversation-as-community-how-shared-dialogue-creates-belonging",
    label: "Communication & Empathy"
  },
  {
    title: "The Parent-Child Connection Gap: Bridging Generational Communication Divides",
    URL: "the-parent-child-connection-gap-bridging-generational-communication-divides",
    label: "Family & Parenting"
  },
  {
    title: "Why Your History Teacher Was Right: The Socratic Method for Modern Conversations",
    URL: "why-your-history-teacher-was-right-the-socratic-method-for-modern-conversations",
    label: "Communication & Empathy"
  },
  {
    title: "The Grief Conversation: Supporting Loved Ones Through Loss With Words",
    URL: "the-grief-conversation-supporting-loved-ones-through-loss-with-words",
    label: "Love & Relationships"
  },
  {
    title: "Beyond Binary Thinking: Conversations That Embrace Complexity and Nuance",
    URL: "beyond-binary-thinking-conversations-that-embrace-complexity-and-nuance",
    label: "Communication & Empathy"
  },
  {
    title: "The Post-Argument Connection: Conversations That Heal After Conflict",
    URL: "the-post-argument-connection-conversations-that-heal-after-conflict",
    label: "Communication & Empathy"
  },
  {
    title: "Why Therapy Works: The Power of Structured Conversation for Mental Health",
    URL: "why-therapy-works-the-power-of-structured-conversation-for-mental-health",
    label: "Professional & Workplace"
  },
  {
    title: "The Connection Economy: Why Relationship Skills Are the New Workplace Currency",
    URL: "the-connection-economy-why-relationship-skills-are-the-new-workplace-currency",
    label: "Love & Relationships"
  },
  {
    title: "Conversation as Legacy: The Questions Worth Asking Before It's Too Late",
    URL: "conversation-as-legacy-the-questions-worth-asking-before-its-too-late",
    label: "Communication & Empathy"
  },
  {
    title: "The Gratitude Exchange: How Thankfulness Conversations Transform Relationships",
    URL: "the-gratitude-exchange-how-thankfulness-conversations-transform-relationships",
    label: "Love & Relationships"
  },
  {
    title: "The Ultimate Question: Finding Your Purpose Through Dialogue With Others",
    URL: "the-ultimate-question-finding-your-purpose-through-dialogue-with-others",
    label: "Communication & Empathy"
  },
  {
    title: "Why Do We Feel Lonely in a Crowded Room?",
    URL: "why-do-we-feel-lonely-in-a-crowded-room",
    label: "Relationships & Connection"
  },
  {
    title: "How to Turn Small Talk into Deep Conversations",
    URL: "how-to-turn-small-talk-into-deep-conversations",
    label: "Relationships & Connection"
  },
  {
    title: "The One Question That Can Save Your Relationship",
    URL: "the-one-question-that-can-save-your-relationship",
    label: "Relationships & Connection"
  },
  {
    title: "Why We’re Bad at Listening (And How to Fix It)",
    URL: "why-were-bad-at-listening-and-how-to-fix-it",
    label: "Relationships & Connection"
  },
  {
    title: "How to Know If Someone Really Cares About You",
    URL: "how-to-know-if-someone-really-cares-about-you",
    label: "Relationships & Connection"
  },
  {
    title: "The Secret to Building Trust in Any Relationship",
    URL: "the-secret-to-building-trust-in-any-relationship",
    label: "Relationships & Connection"
  },
  {
    title: "Why We Keep Having the Same Fights Over and Over",
    URL: "why-we-keep-having-the-same-fights-over-and-over",
    label: "Relationships & Connection"
  },
  {
    title: "How to Spot Emotional Unavailability in a Partner",
    URL: "how-to-spot-emotional-unavailability-in-a-partner",
    label: "Relationships & Connection"
  },
  {
    title: "The Best Way to Apologize (And Why Most People Get It Wrong)",
    URL: "the-best-way-to-apologize-and-why-most-people-get-it-wrong",
    label: "Relationships & Connection"
  },
  {
    title: "Why Some People Feel Like Home",
    URL: "why-some-people-feel-like-home",
    label: "Relationships & Connection"
  },
  {
    title: "How to Reconnect with Someone You’ve Drifted Apart From",
    URL: "how-to-reconnect-with-someone-youve-drifted-apart-from",
    label: "Relationships & Connection"
  },
  {
    title: "The One Conversation Every Couple Needs to Have",
    URL: "the-one-conversation-every-couple-needs-to-have",
    label: "Relationships & Connection"
  },
  {
    title: "Why We’re Afraid to Be Vulnerable in Relationships",
    URL: "why-were-afraid-to-be-vulnerable-in-relationships",
    label: "Relationships & Connection"
  },
  {
    title: "How to Know If You’re in a Toxic Relationship",
    URL: "how-to-know-if-youre-in-a-toxic-relationship",
    label: "Relationships & Connection"
  },
  {
    title: "The Best Questions to Ask on a First Date",
    URL: "the-best-questions-to-ask-on-a-first-date",
    label: "Relationships & Connection"
  },
  {
    title: "Why We Fall for People Who Aren’t Good for Us",
    URL: "why-we-fall-for-people-who-arent-good-for-us",
    label: "Relationships & Connection"
  },
  {
    title: "How to Keep the Spark Alive in a Long-Term Relationship",
    URL: "how-to-keep-the-spark-alive-in-a-long-term-relationship",
    label: "Relationships & Connection"
  },
  {
    title: "Why We Ghost People (And How to Stop)",
    URL: "why-we-ghost-people-and-how-to-stop",
    label: "Relationships & Connection"
  },
  {
    title: "How to Tell If Someone Is Lying to You",
    URL: "how-to-tell-if-someone-is-lying-to-you",
    label: "Relationships & Connection"
  },
  {
    title: "The One Thing That Kills Relationships Faster Than Anything Else",
    URL: "the-one-thing-that-kills-relationships-faster-than-anything-else",
    label: "Relationships & Connection"
  },

  // 21–40: Self-Discovery & Growth
  {
    title: "How to Stop Overthinking Everything",
    URL: "how-to-stop-overthinking-everything",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why You Feel Stuck in Life (And How to Move Forward)",
    URL: "why-you-feel-stuck-in-life-and-how-to-move-forward",
    label: "Self-Discovery & Growth"
  },
  {
    title: "The One Habit That Can Change Your Life",
    URL: "the-one-habit-that-can-change-your-life",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Find Your Purpose in Life",
    URL: "how-to-find-your-purpose-in-life",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why We’re Afraid to Be Ourselves",
    URL: "why-were-afraid-to-be-ourselves",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Stop Seeking Validation from Others",
    URL: "how-to-stop-seeking-validation-from-others",
    label: "Self-Discovery & Growth"
  },
  {
    title: "The One Question That Can Change Your Life",
    URL: "the-one-question-that-can-change-your-life",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why We Procrastinate (And How to Stop)",
    URL: "why-we-procrastinate-and-how-to-stop",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Let Go of What’s Holding You Back",
    URL: "how-to-let-go-of-whats-holding-you-back",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why We’re So Hard on Ourselves",
    URL: "why-were-so-hard-on-ourselves",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Build Confidence from the Inside Out",
    URL: "how-to-build-confidence-from-the-inside-out",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why We Fear Failure (And How to Overcome It)",
    URL: "why-we-fear-failure-and-how-to-overcome-it",
    label: "Self-Discovery & Growth"
  },
  {
    title: "The One Thing You Need to Do to Be Happier",
    URL: "the-one-thing-you-need-to-do-to-be-happier",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Stop Comparing Yourself to Others",
    URL: "how-to-stop-comparing-yourself-to-others",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why We Self-Sabotage (And How to Stop)",
    URL: "why-we-self-sabotage-and-how-to-stop",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Find Joy in the Little Things",
    URL: "how-to-find-joy-in-the-little-things",
    label: "Self-Discovery & Growth"
  },
  {
    title: "Why We’re Afraid of Change (And How to Embrace It)",
    URL: "why-were-afraid-of-change-and-how-to-embrace-it",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Stop Living on Autopilot",
    URL: "how-to-stop-living-on-autopilot",
    label: "Self-Discovery & Growth"
  },
  {
    title: "The One Thing You Need to Let Go of to Move Forward",
    URL: "the-one-thing-you-need-to-let-go-of-to-move-forward",
    label: "Self-Discovery & Growth"
  },
  {
    title: "How to Stop Feeling Like You’re Not Enough",
    URL: "how-to-stop-feeling-like-youre-not-enough",
    label: "Self-Discovery & Growth"
  },

  // 41–60: Meaningful Conversations
  {
    title: "How to Have Deeper Conversations with Anyone",
    URL: "how-to-have-deeper-conversations-with-anyone",
    label: "Meaningful Conversations"
  },
  {
    title: "Why We Avoid Difficult Conversations (And How to Start Them)",
    URL: "why-we-avoid-difficult-conversations-and-how-to-start-them",
    label: "Meaningful Conversations"
  },
  {
    title: "The Best Questions to Ask to Get to Know Someone",
    URL: "the-best-questions-to-ask-to-get-to-know-someone",
    label: "Meaningful Conversations"
  },
  {
    title: "How to Listen So People Feel Heard",
    URL: "how-to-listen-so-people-feel-heard",
    label: "Meaningful Conversations"
  },
  {
    title: "Why We Need to Talk About Mental Health More",
    URL: "why-we-need-to-talk-about-mental-health-more",
    label: "Meaningful Conversations"
  },
  {
    title: "How to Have Better Conversations with Your Partner",
    URL: "how-to-have-better-conversations-with-your-partner",
    label: "Meaningful Conversations"
  },
  {
    title: "Why We’re Bad at Asking for Help",
    URL: "why-were-bad-at-asking-for-help",
    label: "Meaningful Conversations"
  },
  {
    title: "How to Turn Awkward Silences into Meaningful Moments",
    URL: "how-to-turn-awkward-silences-into-meaningful-moments",
    label: "Meaningful Conversations"
  },
  {
    title: "Why We Need to Talk About Money More Openly",
    URL: "why-we-need-to-talk-about-money-more-openly",
    label: "Meaningful Conversations"
  },
  {
    title: "How to Have Conversations That Actually Matter",
    URL: "how-to-have-conversations-that-actually-matter",
    label: "Meaningful Conversations"
  },

  // 61–80: Trending Topics (actually 61–70 given data)
  {
    title: "Why Everyone’s Talking About Emotional Intelligence",
    URL: "why-everyones-talking-about-emotional-intelligence",
    label: "Trending Topics"
  },
  {
    title: "How to Set Boundaries Without Feeling Guilty",
    URL: "how-to-set-boundaries-without-feeling-guilty",
    label: "Trending Topics"
  },
  {
    title: "Why We’re Obsessed with Self-Care (And How to Do It Right)",
    URL: "why-were-obsessed-with-self-care-and-how-to-do-it-right",
    label: "Trending Topics"
  },
  {
    title: "How to Spot a Narcissist (And What to Do About It)",
    URL: "how-to-spot-a-narcissist-and-what-to-do-about-it",
    label: "Trending Topics"
  },
  {
    title: "Why We’re All Burned Out (And How to Recover)",
    URL: "why-were-all-burned-out-and-how-to-recover",
    label: "Trending Topics"
  },
  {
    title: "How to Deal with Toxic People in Your Life",
    URL: "how-to-deal-with-toxic-people-in-your-life",
    label: "Trending Topics"
  },
  {
    title: "Why We’re All Addicted to Our Phones (And How to Break Free)",
    URL: "why-were-all-addicted-to-our-phones-and-how-to-break-free",
    label: "Trending Topics"
  },
  {
    title: "How to Stop People-Pleasing and Start Living for Yourself",
    URL: "how-to-stop-people-pleasing-and-start-living-for-yourself",
    label: "Trending Topics"
  },
  {
    title: "Why We’re All Talking About Attachment Styles",
    URL: "why-were-all-talking-about-attachment-styles",
    label: "Trending Topics"
  },
  {
    title: "How to Stop Over-apologizing for Everything",
    URL: "how-to-stop-over-apologizing-for-everything",
    label: "Trending Topics"
  }
];

export default DatabaseBlogData;